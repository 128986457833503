.App {
  background-color: #030303;
  min-height: 100vh;
}

.App-logo {
  height: 14vmin;
  pointer-events: none;
  margin-left: 0px;
}

.fp-watermark{
  display: none;
}

/* Media query for screens with max width 768px (adjust as needed) */
@media (max-width: 768px) {
  iframe {
      height: 300px; /* Set iframe height for mobile */
      width: 95%;
  }
  #beta{
    margin-left: 10px;
  }
  #flogo{
    width: 40%;
  }
}

/* Larger screens (PC) */
@media (min-width: 769px) {
  iframe {
      height: 450px; /* Set iframe height for PC */
      width: 65%;
  }
  #join{
    margin-right: 10px;
  }
  #flogo{
    width: 20%;
  }
}


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #030303;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.container {
  background-color: #030303;
  height: 100vh; /* Set container height to viewport height */
}

.row1 {
  display: flex; 
}
li::marker {
  border: 80px solid green;
}

.active-link {
  color: grey; /* Set the desired color for the active link */
}

.item,
.item-1,
.item-2 {
  padding:20px 0 0 0;
}

.item-1{
}
.item-2 {
  margin-left: 40px;
}

.scroll-element {
  /* Define the initial state of the element */
  opacity: 0;
  transform: translateY(80px);
  transition: opacity 2s, transform 3s;
}

.scroll-element.animate {
  /* Define the animated state of the element */
  opacity: 1;
  transform: translateY(0);
}

.btn{
  border-radius: 15px;
}

#scrollDownButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 9999;
}

/* Optional style for hover effect */
#scrollDownButton:hover {
  background-color: #0056b3;
}